import './case_study.css';
import CaseText from './CaseText/CaseText';

const CaseStudy = () => {

    return (
        <div className="case-container">
            <div className="parent">
                <CaseText/>
            </div>
        </div>
    );
}

export default CaseStudy;
