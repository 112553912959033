import React from 'react';
import styles from './AdobeSectionTitle.module.css'; // Import the CSS module

const AdobeSectionTitle = () => {
    return (
        <div className={styles.fullContainer}>
            <h2 className={styles.title}>STARTING animation process in ADOBE
            AFTEREFFECTS</h2>
        </div>

    );
}

export default AdobeSectionTitle;
